import React, { Component } from 'react';

import WhatIsFlamingo from './views/what-is-flamingo';
import WhenAvailable from './views/when-available';
import HowFind from './views/how-find';
import HowStart from './views/how-start';
import WherePark from './views/where-park';
import HowCost from './views/how-cost';
import HowFar from './views/how-far';
import WhatCities from './views/what-cities';
import HowFast from './views/how-fast';
import UnlockMultiple from './views/unlock-multiple';
import CanTransport from './views/can-transport';
import RideRain from './views/ride-rain';
import NotWorking from './views/not-working';
import NotOnMap from './views/not-on-map';
import SafetyGuidelines from './views/safety-guidelines';
import Emergency from './views/emergency';
import FreeHelmet from './views/free-helmet';
import NeedHelmet from './views/need-helmet';
import ReportDangerous from './views/report-dangerous';
import ReportParking from './views/report-parking';
import ReportUnlocked from './views/report-unlocked';
import StudentDiscount from './views/student-discount';
import EarnDiscount from './views/earn-discount';
import AddPromo from './views/add-promo';
import PendingCharge from './views/pending-charge';
import RideReceipt from './views/ride-receipt';
import IncurringCharges from './views/incurring-charges';
import RemoveCard from './views/remove-card';
import CloseAccount from './views/close-account';
import Stolen from './views/stolen';
import Contact from './views/contact';
import Charger from './views/charger';
import Feeder from './views/feeder';
import Covid2 from './views/covid-2';
import Covid3 from './views/covid-3';
import Covid4 from './views/covid-4';
import LowSpeed from './views/low-speed';
import Braking from './views/braking';
import Max from './views/max';
import Auckland from './views/auckland';
import Ruru from './views/ruru';
import YearFree from './views/year-free';
import Give20 from './views/give-20';
import HelmetSelfie from './views/helmet-selfie';
import Homegrown from './views/homegrown';
import MaxChch from './views/max-chch';
import Christchurch from './views/christchurch';
import Group from './views/group';
import WgtnGo from './views/wgtn-go';
import Palmy from './views/palmy';
import Waimak from './views/waimak';
import Station from './views/station';
import WgtnPrice from './views/wgtn-price';
import PalmyPrice from './views/palmy-price';
import Bike from './views/bike';
import RoundTheBays from './views/round-the-bays';
import CubaDupa from './views/cubadupa';
import Porirua from './views/porirua';
import PoriruaPrice from './views/porirua-price';
import WaimakPrice from './views/waimak-price';
import Matariki from './views/matariki';
import TaranakiWharf from './views/taranaki-wharf';
import Sky from './views/sky';
import NewYears from './views/new-years';
import Outage from './views/outage';
import NZComp from './views/nz-comp';
import NewtownToSea from './views/newtown-to-sea';
import Vote23 from './views/vote23';
import Gen5 from './views/gen5';
import PoriruaEvent from './views/porirua-event';
import NewtownFestival from './views/newtown-festival';
import FredAgain from './views/fredagain';
import PoriruaYear from './views/porirua-year';
import RoadSafetyWeek from './views/road-safety-week';
import Dunedin from './views/dunedin';
import DunedinStadium from './views/dunedin-stadium';
import DragComp from './views/drag-comp';
import EdenPark from './views/eden-park';
import SantaParade from './views/santa-parade';

const pages = {
  'what-is-flamingo': { component: <WhatIsFlamingo />, title: 'What is Flamingo?' },
  'when-available': { component: <WhenAvailable />, title: 'When are scooters available for riding?' },
  'how-find': { component: <HowFind />, title: 'How can I find a scooter using the App?' },
  'how-start': { component: <HowStart />, title: 'How do I start a ride?' },
  'where-park': { component: <WherePark />, title: 'Where should I park my scooter after finishing my ride?' },
  'how-cost': { component: <HowCost />, title: 'How much does it cost to ride?' },
  'how-far': { component: <HowFar />, title: 'How far can I ride a scooter?' },
  'what-cities': { component: <WhatCities />, title: 'What cities do you operate in?' },
  'how-fast': { component: <HowFast />, title: 'How fast can the scooters go?' },
  'unlock-multiple': { component: <UnlockMultiple />, title: 'Can I unlock multiple scooters at once?' },
  'can-transport': { component: <CanTransport />, title: 'Can I transport your scooters in my car or on public transport to take them somewhere else to ride?' },
  'ride-rain': { component: <RideRain />, title: 'Can I ride in the rain?' },
  'not-working': { component: <NotWorking />, title: 'What if my scooter isn’t working?' },
  'not-on-map': { component: <NotOnMap />, title: 'Why can’t I find the vehicle shown on the map?' },
  'safety-guidelines': { component: <SafetyGuidelines />, title: 'Safety Guidelines' },
  'emergency': { component: <Emergency />, title: 'Emergency' },
  'free-helmet': { component: <FreeHelmet />, title: 'How can I order a free helmet?' },
  'need-helmet': { component: <NeedHelmet />, title: 'Do I need to wear a helmet?' },
  'report-dangerous': { component: <ReportDangerous />, title: 'How do I report a dangerous rider?' },
  'report-parking': { component: <ReportParking />, title: 'How do I report unsafe parking?' },
  'report-unlocked': { component: <ReportUnlocked />, title: 'How do I report an unlocked scooter?' },
  'student-discount': { component: <StudentDiscount />, title: 'What is the Flamingo student discount plan and how do I join?' },
  'earn-discount': { component: <EarnDiscount />, title: 'How can I earn ride discounts?' },
  'add-promo': { component: <AddPromo />, title: 'How do I add a promo code?' },
  'pending-charge': { component: <PendingCharge />, title: 'Why is there a pending charge on my card?' },
  'ride-receipt': { component: <RideReceipt />, title: 'How do I get a copy of my ride receipt?' },
  'incurring-charges': { component: <IncurringCharges />, title: 'Why am I still incurring charges after I lock the scooter?' },
  'remove-card': { component: <RemoveCard />, title: 'How do I remove my credit card?' },
  'close-account': { component: <CloseAccount />, title: 'How do I close my Flamingo account?' },
  'stolen': { component: <Stolen />, title: 'Do I pay if the scooter is stolen?' },
  'contact': { component: <Contact />, title: 'Contact Flamingo' },
  'charger': { component: <Charger />, title: 'Flamingo Feeder' },
  'feeder': { component: <Feeder />, title: 'Flamingo Feeder' },
  'covid2': { component: <Covid2 />, title: 'COVID-19 Level 2' },
  'covid3': { component: <Covid3 />, title: 'COVID-19 Level 3' },
  'covid4': { component: <Covid4 />, title: 'COVID-19 Level 4' },
  'low-speed': { component: <LowSpeed />, title: 'Wellington Waterfront' },
  'winter-akl': { component: <Braking max={true} />, title: 'Winter Riding' },
  'winter': { component: <Braking />, title: 'Winter Riding' },
  'max': { component: <Max />, title: 'Max Wellington' },
  'auckland': { component: <Auckland />, title: 'Flamingo Auckland' },
  'ruru': { component: <Ruru />, title: 'Ruru Parking Racks' },
  'year-free': { component: <YearFree />, title: 'Win a year of FREE riding!' },
  'give-20': { component: <Give20 />, title: 'Give 20, Get 20!' },
  'helmet-selfie': { component: <HelmetSelfie />, title: 'Helmet Selfies' },
  'homegrown': { component: <Homegrown />, title: 'Homegrown Waterfront Restrictions' },
  'max-christchurch': { component: <MaxChch />, title: 'Max Christchurch' },
  'christchurch': { component: <Christchurch />, title: 'Flamingo Christchurch' },
  'group': { component: <Group />, title: 'Group Rides' },
  'wgtn-go': { component: <WgtnGo />, title: 'Go Pass Wellington' },
  'palmy': { component: <Palmy />, title: 'Palmerston North' },
  'waimak': { component: <Waimak />, title: 'Waimakariri' },
  'station': { component: <Station />, title: 'Railway Station' },
  'wgtn-pricing': { component: <WgtnPrice />, title: 'Pricing Change' },
  'porirua-pricing': { component: <PoriruaPrice />, title: 'Pricing Change' },
  'palmy-pricing': { component: <PalmyPrice />, title: 'Pricing Change' },
  'waimak-pricing': { component: <WaimakPrice />, title: 'Pricing Change' },
  'bike': { component: <Bike />, title: 'Flamingo E-Bikes' },
  'rtb': { component: <RoundTheBays />, title: 'Round the Bays' },
  'cubadupa': { component: <CubaDupa />, title: 'CubaDupa 2023' },
  'porirua': { component: <Porirua />, title: 'Porirua' },
  'matariki': { component: <Matariki />, title: 'Matariki Ahi Ka 2023' },
  'taranaki-wharf': { component: <TaranakiWharf />, title: 'Hinemoana Ka Eke' },
  'sky': { component: <Sky />, title: 'All Blacks v Argentina' },
  'outage': { component: <Outage />, title: 'Network Outage - 1 Sep' },
  'nz-comp': { component: <NZComp />, title: 'Support NZ Competition' },
  'n2s': { component: <NewtownToSea />, title: 'Free Learn to Ride' },
  'vote23': { component: <Vote23 />, title: 'Vote 2023 - NZ General Election' },
  'gen5': { component: <Gen5 />, title: 'Flamingo Gen 5' },
  'porirua-event': { component: <PoriruaEvent />, title: 'Porirua Event' },
  'new-years': { component: <NewYears />, title: 'New Year\'s Eve' },
  'newtown-festival': { component: <NewtownFestival />, title: 'Newtown Festival' },
  'fredagain': { component: <FredAgain />, title: 'Fred again..' },
  'porirua-year': { component: <PoriruaYear />, title: '1 Year Porirua' },
  'road-safety-week': { component: <RoadSafetyWeek />, title: 'Road Safety Week' },
  'road-safety-week-wn': { component: <RoadSafetyWeek wellington={true} />, title: 'Road Safety Week' },
  'dunedin': { component: <Dunedin />, title: 'Flamingo Dunedin' },
  'dunedin-stadium': { component: <DunedinStadium />, title: 'All Blacks v England' },
  'palmy-drag-fest': { component: <DragComp />, title: 'Palmy Drag Fest 2024' },
  'eden-park': { component: <EdenPark />, title: 'Eden Park' },
  'santa-parade': { component: <SantaParade />, title: 'Auckland Santa Parade' },
};

class Webview extends Component {
  renderMissing() {
    return (
      <div className="fm-container">
        <p className="fm-webview-title">Not Found</p>
        <p className="fm-webview-text">This support page is not avaliable. Please try returning to the previous page and finding a different topic.</p>
        <p className="fm-webview-text">If your query is still not resolved, feel free to contact us.</p>
      </div>
    );
  }

  render() {
    const page = pages[this.props.match.params.page];
    if (page) {
      document.title = page.title;
      return <div className="fm-webview">{ page.component }</div>;
    }
    return <div className="fm-webview">{ this.renderMissing() }</div>;
  }
}

export default Webview;
