import React, { Component } from 'react';

class SantaParade extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">Auckland Santa Parade</p>
        <p className="fm-max-text">The Auckland Santa Parade is taking place in the city today and large crowds are expected.</p>
        <img className="fm-max-photo" alt="Santa Parade" src="https://storage.googleapis.com/flamingo-static/images/support/santa.png" />
        <p className="fm-max-text">To ensure the parade runs smoothly and the route stays clear for everyone, scooters <b>cannot be parked on Queen Street, Mayoral Drive or Customs Street East during the event</b>. Please park safely on nearby side streets instead.</p>
        <p className="fm-max-text">Use the Flamingo app to find suitable parking areas marked with the blue 🅿️ icon. Parking responsibly helps keep pathways safe and accessible for the crowds.</p>
        <p className="fm-max-text"><b>Enjoy 10 minutes FREE!</b> Enter the code <b>SANTA</b> in the "Payment" tab of the Flamingo app to get 10 minutes free for your ride.</p>
        <p className="fm-max-end">Safe Riding,<br />The Flamingo Team</p>
        <br />
      </div>
    );
  }
}

export default SantaParade;
